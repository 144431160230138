// Core files
@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/mixins";
@import "../fonts/fonts"; // Custom Fonts
@import "variables"; // Custom Variables
@import "../../../../node_modules/bootstrap/scss/bootstrap";

// Themes
@import "theme-light";
@import "theme-dark";

// Structure
@import "../structure/topbar";
@import "../structure/page-head";
@import "../structure/vertical";
@import "../structure/layouts";

// Components
@import "../components/waves";
@import "../components/avatar";
@import "../components/accordion";
@import "../components/helper";
@import "../components/preloader";
@import "../components/forms";
@import "../components/widgets";
@import "../components/ribbons";
@import "../components/toast";
@import "../components/scrollspy";

// Bootstrap Components
@import "../components/reboot";
@import "../components/alerts";
@import "../components/backgrounds";
@import "../components/badge";
@import "../components/buttons";
@import "../components/breadcrumb";
@import "../components/card";
@import "../components/dropdown";
@import "../components/nav";
@import "../components/table";
@import "../components/modal";
@import "../components/pagination";
@import "../components/progress";
@import "../components/popover";
@import "../components/type";
@import "../components/form-check";
@import "../components/form-control";
@import "../components/list-group";

// Plugins
@import "../plugins/custom-scrollbar";
@import "../plugins/dropzone";
@import "../plugins/range-slider";
@import "../plugins/sortablejs";
@import "../plugins/tour";
@import "../plugins/swiper";
@import "../plugins/filepond";
@import "../plugins/form-input-spin";
@import "../plugins/gridjs";
@import "../plugins/listjs";
@import "../plugins/apexcharts";
@import "../plugins/chartjs";
@import "../plugins/echarts";
@import "../plugins/autocomplete";
@import "../plugins/fullcalendar";
@import "../plugins/datatables";
@import "../plugins/select2";
@import "../plugins/toastify";
@import "../plugins/choices";
@import "../plugins/flatpicker";
@import "../plugins/flag-input";

// Pages
@import "../pages/authentication";
@import "../pages/coming-soon";
@import "../pages/dashboard";
@import "../pages/errors";
@import "../pages/file-manager";
@import "../pages/invoice";
@import "../pages/landing";
@import "../pages/profile";
@import "../pages/search-results";

// Custom Scss
@import "custom.scss";

// Angular Custom Scss
@import "angular-custom.scss";

// Import plain CSS file
@import "../../../../node_modules/ngx-toastr/toastr.css";