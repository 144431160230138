//
// _form-control.scss
//

.form-control {
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: #232832;
  padding: 12px 16px;
}

.form-control:hover {
  border: 1px solid rgba($color: $white, $alpha: 0.1);
}

.form-control:active {
  border: 1px solid rgba($color: $white, $alpha: 0.05);
}

.form-control:focus {
  border: 1px solid rgba($color: $white, $alpha: 0.2);
}

.form-control::placeholder {
  opacity: 0.6;
  color: #fff;
}

.form-icon {
  position: relative;

  .form-control-icon {
    padding-left: $input-padding-x * 3;
    position: relative;
  }

  i {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 18px;
    display: flex;
    align-items: center;
  }

  &.right {
    .form-control-icon {
      padding-right: $input-padding-x * 3;
      padding-left: $input-padding-x;
      position: relative;
    }

    i {
      left: auto;
      right: 18px;
    }
  }
}

[data-layout-mode="dark"] {
  .form-select {
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$gray-400}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>"));
  }
}

.form-control.is-invalid {
  border-color: #f44f4f !important;
}