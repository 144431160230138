//
// _buttons.scss
//

button,
a {
  outline: none !important;
  position: relative;
}

.btn {
  box-shadow: $element-shadow;

  span {
    font-weight: 700;
  }
}

:is(.btn.show, .btn:first-child:active, :not(.btn-check) + .btn:active) {
  border-color: transparent;
}

// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: calc(#{$btn-padding-y * 2} + #{$btn-line-height}em + #{$btn-border-width * 2});
  width: calc(#{$btn-padding-y * 2} + #{$btn-line-height}em + #{$btn-border-width * 2});
  padding: 0;

  :is(i, svg, img) {
    vertical-align: middle;
  }

  &.btn-sm {
    height: calc(#{$btn-padding-y-sm * 2} + #{$btn-line-height}em + #{$btn-border-width * 2});
    width: calc(#{$btn-padding-y-sm * 2} + #{$btn-line-height}em + #{$btn-border-width * 2});
  }

  &.btn-lg {
    height: calc(#{$btn-padding-y-lg * 2} + #{$btn-line-height}em + #{$btn-border-width * 2});
    width: calc(#{$btn-padding-y-lg * 2} + #{$btn-line-height}em + #{$btn-border-width * 2});
  }
}

//
// Soft Buttons
//

@each $color, $value in $theme-colors {
  .btn-soft-#{$color} {
    --#{$prefix}btn-color: #{$value};
    --#{$prefix}btn-bg: #{rgba($value, 0.1)};
    --#{$prefix}btn-border-color: transparent;
    --#{$prefix}btn-hover-color: #{$white};
    --#{$prefix}btn-hover-bg: #{shade-color($value, 10%)};
    --#{$prefix}btn-hover-border-color: transparent;
    --#{$prefix}btn-focus-shadow-rgb: #{to-rgb($value)};
    --#{$prefix}btn-active-color: var(--#{$prefix}btn-hover-color);
    --#{$prefix}btn-active-bg: #{shade-color($value, 20%)};
    --#{$prefix}btn-active-border-color: transparent;
  }
}

//
// Ghost Buttons
//

@each $color, $value in $theme-colors {
  .btn-ghost-#{$color} {
    --#{$prefix}btn-color: #{$value};
    --#{$prefix}btn-bg: transparent;
    --#{$prefix}btn-border-color: transparent;
    --#{$prefix}btn-hover-color: #{$value};
    --#{$prefix}btn-hover-bg: #{rgba($value, 0.1)};
    --#{$prefix}btn-hover-border-color: transparent;
    --#{$prefix}btn-focus-shadow-rgb: #{to-rgb($value)};
    --#{$prefix}btn-active-color: #{$value};
    --#{$prefix}btn-active-bg: #{rgba($value, 0.1)};
    --#{$prefix}btn-active-border-color: transparent;
  }
}

.btn-ghost-secondary {
  color: var(--#{$prefix}gray-700);
}

//button with icon
.btn-label {
  position: relative;
  padding-left: 44px;

  .label-icon {
    position: absolute;
    width: 35.5px;
    left: -$btn-border-width;
    top: -$btn-border-width;
    bottom: -$btn-border-width;
    background-color: rgba($white, 0.1);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.btn-light {
    .label-icon {
      background-color: rgba($dark, 0.05);
    }
  }

  &.right {
    padding-left: $btn-padding-x;
    padding-right: 44px;

    .label-icon {
      right: -$btn-border-width;
      left: auto;
    }
  }
}

// Animation Button

.btn-animation {
  overflow: hidden;
  transition: border-color 0.3s, background-color 0.3s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);

  &::after {
    content: attr(data-text);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transform: translate3d(0, 25%, 0);
    padding: $btn-padding-y $btn-padding-x;
    transition: transform 0.3s, opacity 0.3s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  }

  >span {
    display: block;
    transition: transform 0.3s, opacity 0.3s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  }

  &:hover {
    &::after {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    >span {
      opacity: 0;
      transform: translate3d(0, -25%, 0);
    }
  }
}

@mixin button-variant($bg) {
  &.btn-animation {
    background-color: $bg;
    border-color: $bg !important;

    &:is(:hover, :focus, :active, :focus-visible) {
      color: $bg;
      background-color: rgba($bg, 0.1);
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value);
  }
}

//btn group
.btn-group-vertical {
  label {
    margin-bottom: 0;
  }
}

// btn group radio
.btn-group.radio {
  .btn {
    border: none;
  }

  .btn-check:active+.btn-light,
  .btn-check:checked+.btn-light,
  .btn-light.active,
  .btn-light.dropdown-toggle.show,
  .btn-light:active {
    background-color: rgba($info, 0.2);
    color: $info;
  }
}

// theme dark
[data-layout-mode="dark"] {

  .btn-check:focus+.btn-light,
  .btn-check:focus+.btn-outline-light,
  .btn-check:focus+.btn-soft-light,
  .btn-soft-light:focus {
    color: var(--#{$prefix}dark);
    background-color: rgba(var(--#{$prefix}light-rgb), 0.75);
    border-color: rgba(var(--#{$prefix}light-rgb), 0.75);
  }

  .btn-check:active+.btn-light,
  .btn-check:active+.btn-outline-light,
  .btn-check:active+.btn-soft-light,
  .btn-check:checked+.btn-light,
  .btn-check:checked+.btn-outline-light,
  .btn-check:checked+.btn-soft-light,
  .btn-light.active,
  .btn-light:active,
  .btn-outline-light.active,
  .btn-outline-light:active,
  .btn-soft-light.active,
  .btn-soft-light:active,
  .show>.btn-light.dropdown-toggle,
  .show>.btn-outline-light.dropdown-toggle,
  .show>.btn-soft-light.dropdown-toggle {
    color: var(--#{$prefix}dark);
    background-color: rgba(var(--#{$prefix}light-rgb), 0.75);
    border-color: rgba(var(--#{$prefix}light-rgb), 0.75);
  }

  .btn-check:focus+.btn-dark,
  .btn-check:focus+.btn-outline-dark,
  .btn-check:focus+.btn-soft-dark,
  .btn-soft-dark:focus {
    color: var(--#{$prefix}light);
    background-color: rgba(var(--#{$prefix}dark-rgb), 0.75);
    border-color: rgba(var(--#{$prefix}dark-rgb), 0.75);
  }

  .btn-check:active+.btn-dark,
  .btn-check:active+.btn-outline-dark,
  .btn-check:active+.btn-soft-dark,
  .btn-check:checked+.btn-dark,
  .btn-check:checked+.btn-outline-dark,
  .btn-check:checked+.btn-soft-dark,
  .btn-dark.active,
  .btn-dark:active,
  .btn-outline-dark.active,
  .btn-outline-dark:active,
  .btn-soft-dark.active,
  .btn-soft-dark:active,
  .show>.btn-dark.dropdown-toggle,
  .show>.btn-outline-dark.dropdown-toggle,
  .show>.btn-soft-dark.dropdown-toggle {
    color: var(--#{$prefix}light);
    background-color: rgba(var(--#{$prefix}dark-rgb), 0.75);
    border-color: rgba(var(--#{$prefix}dark-rgb), 0.75);
  }

  // soft

  .btn-soft-light {
    color: var(--#{$prefix}dark);
    background-color: rgba(var(--#{$prefix}light-rgb), 0.1);

    &:hover {
      color: var(--#{$prefix}dark);
      background-color: var(--#{$prefix}light);
    }
  }

  .btn-soft-dark {
    color: var(--#{$prefix}dark);
    background-color: rgba(var(--#{$prefix}dark-rgb), 0.1);

    &:hover {
      color: var(--#{$prefix}light);
      background-color: var(--#{$prefix}dark);
    }
  }

  // ghost
  .btn-ghost-light {
    color: var(--#{$prefix}dark);

    &:is(:hover, :focus, :active, :focus-visible) {
      color: var(--#{$prefix}dark);
      background-color: rgba(var(--#{$prefix}light-rgb), 0.5);
    }
  }

  .btn-ghost-dark {
    color: var(--#{$prefix}dark);

    &:is(:hover, :focus, :active, :focus-visible) {
      color: var(--#{$prefix}dark);
      background-color: rgba(var(--#{$prefix}dark-rgb), 0.1);
    }
  }
}

.btn-load {

  .spinner-border,
  .spinner-grow {
    height: 19px;
    width: 19px;
  }
}

//Border Bottom Buttons Style
@each $color, $value in $theme-colors {
  :is(.btn-#{$color}, .btn-outline-#{$color}, .btn-soft-#{$color}) {
    &.btn-border {
      border-bottom: 2px solid darken($value, 8%);
    }
  }
}

//custom toggle
.custom-toggle {
  .icon-off {
    display: none;
  }

  &.active {
    .icon-on {
      display: none;
    }

    .icon-off {
      display: block;
    }
  }
}

// custom-buttons

// primary-buttons

.btn-primary {
  border-radius: 64px;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: $white;
  padding: 12px 32px;
  transition: 0.1s ease;
  border: 1px solid #ffffff00;
  z-index: 4;
  position: relative;
}

.btn-primary:hover {
  box-shadow: 0px 7px 4px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid #ffffff00;
}

.btn-primary:active {
  box-shadow: none;
}

.btn-primary:disabled {
  background: rgba(255, 255, 255, 0.02);
  border: 0;
}

.btn-primary-default {
  background: $default-gradient;
}

.btn-primary-default:hover {
  background: linear-gradient(263deg,
      rgba(153, 153, 153, 0.1) 8.06%,
      rgba(255, 255, 255, 0.1) 59.15%);
  border: 1px solid #ffffff00;
}

.btn-primary-default:active {
  background: linear-gradient(263deg,
      rgba(153, 153, 153, 0.1) 8.06%,
      rgba(255, 255, 255, 0.1) 59.15%) !important;

  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5) inset;
  background-color: none;
}

.btn-primary-positive {
  background: $green-gradient;
}

.btn-primary-positive:hover {
  background: #0f9a1c;
  transition: none;
}

.btn-primary-positive:active {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: linear-gradient(90deg, #50ad59 50%, #0f9a1c 100%);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75) inset;
}

.btn-primary-negative {
  background: $red-gradient;
}

.btn-primary-negative:hover {
  background: linear-gradient(0deg, #f02323 0%, #f02323 100%);
}

.btn-primary-negative:active {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: linear-gradient(0deg, #ef5353 0%, #ef5353 100%);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75) inset;
}

.btn-primary-gradient {
  background-image: linear-gradient(90deg, #ffb21d 0.24%, #dc232b 61.95%);
  border: 0px;
}

.btn-primary-gradient:hover {
  border: 0px;
}

.btn-primary-gradient span {
  z-index: 10;
  display: flex;
  position: relative;
  color: #fff;
  font-weight: 700;
}

.btn-primary-gradient::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: linear-gradient(90deg,
      #dc232b 0.24%,
      #ffb21d 52.47%,
      #dc232b 99.73%);
  border-radius: 64px;
  transition: all 0.2s ease-in;
}

.btn-primary-gradient:hover::after {
  /* Apply hover state to the button itself */
  opacity: 1;
  transition: all 0.1s ease-in;
}

.btn-primary-gradient:active::after {
  /* Apply hover state to the button itself */
  opacity: 0;
  transition: all 0.1s ease-in;
}

.btn-primary-gradient:active {
  background: radial-gradient(50% 50% at 50% 50%, #e54627 0%, #ef8e1f 100%);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5) inset;
  border: 0px;

  .btn-primary-gradient span {
    opacity: 20%;
    color: black;
  }
}

//secondary buttons

.btn-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 32px;
  border-radius: 64px;
  border: 1px solid rgba(255, 255, 255, 0.05) !important;
  background: none !important;
  transition: all 0.2s ease-in;
  font-weight: 700;
}

.btn-secondary:hover {
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: linear-gradient(263deg,
      rgba(153, 153, 153, 0.02) 8.06%,
      rgba(255, 255, 255, 0.02) 93.21%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
}

.btn-secondary:active span {
  opacity: 0.6;
}

.btn-secondary:active {
  border: 1px solid #3a4150;

  background: var(--Gradient-Gray, linear-gradient(263deg));
  /* Subtle Inner */
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5) inset;
}

.btn-secondary:disabled {
  background: none !important;
  opacity: 0.4;
}

.btn-secondary-positive,
.btn-secondary-positive:hover,
.btn-secondary-positive:active span {
  color: $green;
  transition: none;
}

.btn-secondary-negative,
.btn-secondary-negative:hover,
.btn-secondary-negative:active span {
  color: $red;
  transition: none;
}

//tertiary buttons

.btn-tertiary {
  background: none;
  outline: none;
  border: none;
  text-decoration: underline;
  font-weight: bold;
}

.btn-tertiary:hover {
  opacity: 0.7;
}

.btn-tertiary:active {
  opacity: 0.5;
}

.btn-tertiary:disabled {
  opacity: 0.3;
}

.btn-tertiary-positive,
.btn-tertiary-positive:hover,
.btn-tertiary-positive:active span {
  color: $green;
  text-decoration: underline;
  transition: none;
}

.btn-tertiary-negative,
.btn-tertiary-negative:hover,
.btn-tertiary-negative:active span {
  color: $red;
  text-decoration: underline;
  transition: none;
}

//icon buttons

.btn-icon {
  border-radius: 16px;
  border: none;
}

.btn-icon:hover {
  box-shadow: 0px 7px 4px 0px rgba(0, 0, 0, 0.2);
}

.btn-icon:disabled {
  opacity: 0.3;
  background: $default-gradient;
}

.btn-icon-gradient {
  background: $brand-gradient;
}

.btn-icon-gradient span {
  z-index: 10;
}

.btn-icon-gradient::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: linear-gradient(90deg,
      #dc232b 0.24%,
      #ffb21d 52.47%,
      #dc232b 99.73%);
  border-radius: 16px;
  transition: all 0.2s ease-in;
}

.btn-icon-gradient:hover::after {
  /* Apply hover state to the button itself */
  opacity: 1;
  transition: all 0.1s ease-in;
}

.btn-icon-gradient:active::after {
  /* Apply hover state to the button itself */
  opacity: 0;
  transition: all 0.1s ease-in;
}

.btn-icon-gradient:active {
  background: radial-gradient(50% 50% at 50% 50%, #e54627 0%, #ef8e1f 100%);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5) inset;
  border: 1px (255, 255, 255, 0.5) inset;

  .btn-icon-gradient span {
    opacity: 20%;
    color: black;
  }
}

.btn-icon-default {
  background: $default-gradient;
}

.btn-icon-default:hover {
  background: linear-gradient(263deg, rgba(153, 153, 153, 0.10) 8.06%, rgba(255, 255, 255, 0.10) 59.15%);
}

.btn-icon-default:active {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.50) inset;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.btn-icon-positive {
  background: $green-gradient;
}

.btn-icon-positive:hover {
  background: #0F9A1C;
  transition: none;
}

.btn-icon-positive:active {
  border: 1px solid rgba(0, 0, 0, 0.20);
  background: linear-gradient(90deg, #50AD59 50%, #0F9A1C 100%);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75) inset;
}

.btn-icon-negative {
  background: $red-gradient;
}

.btn-icon-negative:hover {
  background: linear-gradient(0deg, #F02323 0%, #F02323 100%);
}

.btn-icon-negative:active {
  border: 1px solid rgba(0, 0, 0, 0.20);
  background: linear-gradient(0deg, #EF5353 0%, #EF5353 100%);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75) inset;
}

//toggles and switches

.checkbox {
  display: none;
}

.switch {
  align-items: center;
  background-color: $gray-800;
  border-radius: 500px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  gap: 4px;
  padding: 4px 8px;
  position: relative;
  user-select: none;
}

.checkbox:checked~.switch {
  background-color: $green;
}

.checkbox:not(:checked)~.switch {
  background-color: rgba($color: #fff, $alpha: 0.05);
}

.switch-left {
  color: $gray-400;
  font-weight: bold;
}

.switch-right {
  color: white;
  font-weight: bold;
}

.checkbox:checked~.switch .switch-left {
  visibility: hidden;
}

.checkbox:not(:checked)~.switch .switch-right {
  visibility: hidden;
}

.switch-circle {
  height: 32px;
  padding: 5px;
  position: absolute;
  transition: all 0.1s linear;
  width: 32px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
}

.checkbox:checked~.switch .switch-circle {
  left: calc(100% - 32px);
  right: 0;
}

.checkbox:not(:checked)~.switch .switch-circle {

  left: 0;
  right: calc(100% - 32px);
}

.checkbox:checked~.switch .switch-circle-inner {
  background-color: rgba($color: $white, $alpha: 1);
  border-radius: 100%;
  display: block;
  height: 100%;
  width: 100%;
}

.checkbox:not(:checked)~.switch .switch-circle-inner {
  background-color: rgba($color: $white, $alpha: 0.5);
  border-radius: 100%;
  display: block;
  height: 100%;
  width: 100%;
}

.switches-container {
  width: 16rem;
  display: flex;
  padding: 0;
  position: relative;
  background: rgba($color: $white, $alpha: 0.03);
  border-radius: 3rem;
  height: 32px;
  align-items: center;
}

.switches-container input {
  visibility: hidden;
  position: absolute;
  top: 0;
}

.switches-container label {
  width: 50%;
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  color: $gray-100;
  font-weight: 700;
  z-index: 3;
}

.switch-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  z-index: 1;
  transition: transform .2s cubic-bezier(.77, 0, .175, 1);
}

.switch {
  border-radius: 3rem;
  background: $gray-800;
  height: 100%;
}

.switch div {
  width: 100%;
  text-align: center;
  opacity: 0;
  display: block;
  color: var(--switch-text-color);
  transition: opacity .02s cubic-bezier(.77, 0, .175, 1) .02s;
  will-change: opacity;
  position: absolute;
  top: 0;
  left: 0;
}

.switches-container input:nth-of-type(1):checked~.switch-wrapper {
  transform: translateX(0%);
}

.switches-container input:nth-of-type(2):checked~.switch-wrapper {
  transform: translateX(100%);
}

.switches-container input:nth-of-type(1):checked~.switch-wrapper .switch div:nth-of-type(1) {
  opacity: 1;
}

.switches-container input:nth-of-type(2):checked~.switch-wrapper .switch div:nth-of-type(2) {
  opacity: 1;
}

.pagination {
  gap: 4px;
  padding: 8px 14px;
  border-radius: 16px;
  background: rgba($color: $white, $alpha: 0.05);
  font-weight: 600;
}

.page-link {
  background: rgba($color: $white, $alpha: 0.05);
  border: none;
  color: rgba($color: $white, $alpha: 0.7);
}

.page-link:hover {
  color: rgba($color: $white, $alpha: 1);
  background: rgba($color: $white, $alpha: 0.1);
}

.page-item:active {
  color: rgba($color: $white, $alpha: 0.5) !important;
}