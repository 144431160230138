// for UI Samples page, FOR PREVIEWS ONLY!

.gray-900 {
  background-color: $gray-900;
}

.gray-800 {
  background-color: $gray-800;
}

.gray-700 {
  background-color: $gray-700;
}

.gray-600 {
  background-color: $gray-600;
}

.gray-500 {
  background-color: $gray-500;
  color: $gray-700;
}

.gray-400 {
  background-color: $gray-400;
  color: $gray-700;
}

.gray-300 {
  background-color: $gray-300;
  color: $gray-700;
}

.gray-200 {
  background-color: $gray-200;
  color: $gray-800;
}

.gray-100 {
  background-color: $gray-100;
  color: $gray-900;
}

.green-gradient {
  background: $green-gradient;
}

.brown-gradient {
  background: $brown-gradient;
}

.blue-gradient {
  background: $blue-gradient;
}

.red-gradient {
  background: $red-gradient;
}

.toggle-card-full-height {
  min-height: calc(100vh - 14.1rem);
}

.toggle-card-radius {
  border-radius: 40px !important;
}

.color-card {
  display: flex;
  flex-direction: column;
  background-color: rgba($color: $gray-900, $alpha: 0.5);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.03);
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.05);
  padding: 24px;
  height: 300px;
  width: 100%;
}

.color-card div {
  display: flex;
  gap: 8px;
  width: 100%;
  height: 100%;
}

.main {
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
}

.color-container {
  padding: 8px;
  height: 100%;
  border-radius: 8px;
}

.brand-red {
  background-color: $burning-red;
}

.brand-orange {
  background-color: $carrot-curl;
}

.red {
  background-color: $red;
}

.brown {
  background-color: $brown;
}

.green {
  background-color: $green;
}

.blue {
  background-color: $blue;
}


.h-full-screen {
  min-height: calc(100vh - $header-height - 3rem);
}

.text-primary-gradient {
  background: var(--Gradient-2, linear-gradient(90deg, #FFB21D 0.24%, #DC232B 99.73%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.opacity-8 {
  opacity: 0.8;
}

* {

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $gray-800;
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $gray-700;
  }
}