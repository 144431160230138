//
// _authentication.scss
//

.auth-page-wrapper {
  .auth-page-content {
    padding-bottom: $footer-height;
    position: relative;
    z-index: 2;
    width: 100%;
  }

  .footer {
    left: 0;
    background-color: transparent;
    color: var(--#{$prefix}body-color);
  }
}

.auth-one-bg-position {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 380px;

  @media (max-width: 575.98px) {
    height: 280px;
  }
}

.auth-one-bg {
  // background-image: url("../../images/auth-one-bg.jpg");
  background-position: center;
  background-size: cover;

  .bg-overlay {
    background: linear-gradient(to right, darken($primary, 6%), $primary);
    opacity: 0.9;
  }
}

.shape {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;

  >svg {
    width: 100%;
    height: auto;
    fill: var(--#{$prefix}body-bg);
  }
}

// auth-pass-inputgroup

.particles-js-canvas-el {
  position: relative;
}

// signin card title

.signin-other-title {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    right: 0;
    border-top: 1px dashed var(--#{$prefix}border-color);
    top: 10px;
  }

  .title {
    display: inline-block;
    position: relative;
    z-index: 9;
    background-color: var(--#{$prefix}card-bg-custom);
    padding: 2px 16px;
  }
}

// Auth cover

.auth-bg-cover {
  background: $auth-bg-cover;

  >.bg-overlay {
    // background-image: url("../../images/cover-pattern.png");
    background-position: center;
    background-size: cover;
    opacity: 1;
    background-color: transparent;
  }

  .footer {
    color: rgba($white, 0.5);
  }
}

//passowrd validations
#password-contain {
  display: none;

  p {
    padding-left: 13px;

    &.valid {
      color: $success;

      &::before {
        position: relative;
        left: -8px;
        content: "✔";
      }
    }

    &.invalid {
      color: $danger;

      &::before {
        position: relative;
        left: -8px;
        content: "✖";
      }
    }
  }
}

.auth-card {
  border: 0.5px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
  padding: 2.5rem;
  margin: 0;
  border-radius: 24px !important;
  width: 430px;
  transition: all 0.2s ease-in;

  h3 {
    font-family: "Yanone Kaffeesatz", Sans-serif;
    font-size: $h3-font-size;
    font-weight: 600;
  }

  a {
    font-family: "Lato", Sans-serif;
    font-weight: normal;
  }

  .card-body {
    padding: 0;
  }
}

.or-seperator {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}

.or-seperator:before,
.or-seperator:after {
  content: "";
  border-bottom: 1px solid #c9cace;
  flex: 1 0 auto;
}

.modal-dialog-scrollable .modal-content {
  max-height: 75vh;
}

.password-strength-tips__container {
  border: 1px solid rgba(255, 255, 255, 0.05);
  padding: 1rem;
  margin-top: 10px;
  border-radius: 1rem;
  text-align: start;
  font-size: 14px;

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    position: relative;
    padding-left: 20px;
    color: red;

    &::before {
      content: '✘';
      position: absolute;
      left: 0;
      color: red;
    }

    &.valid {
      color: green;

      &::before {
        content: '✔';
        color: green;
      }
    }
  }
}