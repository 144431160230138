//
// toast.scss
//


// toast
.toast {
    &:not(:last-child) {
        margin-bottom: $toast-padding-x;
    }
}

@mixin toast-border-variant($bg) {
    .toast-body {
        color: $bg;
        border-bottom: 3px solid $bg;
    }
}

@each $color, $value in $theme-colors {
    .toast-border-#{$color} {
        @include toast-border-variant($value);
    }
}

.toast-container .toast-error {
    border-radius: 1rem;
    border: 1px solid #F44F4F;
    padding: 0.5rem 2rem;
    color: #F44F4F;
    background: linear-gradient(263deg, rgba(113, 118, 158, 0.05) 8.06%, rgba(219, 210, 255, 0.05) 93.21%);
    text-align: center;
    background-image: none;
    box-shadow: none;
}

.toast-top-center {
    left: 50%;
    transform: translate(-50%, 0px);
    top: 12px;
}